/* eslint-disable class-methods-use-this */

import BaseModel from './_Base'

/**
 * Airport
 * @link https://vuemc.io/#basic-usage
 */
class Airport extends BaseModel {
  // Default attributes that define the "empty" state.
  defaults() {
    return {
      id: null,
      name: '',
    }
  }

  // Attribute mutations to normalize data.
  mutations() {
    return {
      id: id => id || null,
      name: String,
    }
  }

  // ? NOTE: Which validation library to choose?
  // Attribute validation, acts after mutations

  // TODO
  // validation() {
  //   return {

  //   }
  // }

  //--------------------------------
  //        API REQUESTS
  //--------------------------------
}

export default Airport
