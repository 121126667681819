<template>
  <b-card class="mb-0 d-flex h-100">
    <validation-observer ref="locationsFormRef" class="d-flex flex-column h-100" tag="form" @submit.prevent="onSubmit">
      <div class="d-flex justify-content-between align-items-center mb-1">
        <h2 class="mb-0">{{ $t('service.location.title') }}</h2>
        <app-btn-add v-if="$route.name !== 'productShow'" @click="addAirports" />
      </div>
      <p>{{ $t('service.location.introduction') }}</p>

      <b-card class="border" style="max-height: 70vh; overflow-y: auto; overflow-x: hidden">
        <!-- Row Loop -->
        <b-row v-for="(airport, index) in product.airports" :key="index" class="d-flex align-items-center pt-50">
          <b-col v-if="index" cols="12"><hr /></b-col>

          <b-col md="6" class="mb-sm-50">
            <validation-provider #default="{ errors }" :name="$t('common.airport')" rules="required" class="validation-required">
              <b-form-group :label="$t('common.airport')" :label-for="`airport${index}`">
                <v-select
                  v-model="product.airports[index].airport"
                  :disabled="$route.name === 'productShow'"
                  :input-id="`airport${index}`"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :get-option-label="airport => airport.icaoCode ? airport.icaoCode + ' - ' + airport.name : airport.name"
                  :placeholder="$t('common.airport')"
                  :options="airportsOptions"
                  :clearable="false"
                  append-to-body
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="" class="mt-25 d-flex justify-content-center justify-content-between">
            <b-form-checkbox v-model="product.airports[index].active" :disabled="$route.name === 'productShow'" name="check-button" class="mt-50" switch inline>
              {{ $t('common.active') }}
            </b-form-checkbox>

            <!-- Remove button -->
            <b-button-group v-if="$route.name !== 'productShow'">
              <b-button v-ripple.400 size="md" variant="outline-primary" @click="modalDeleteItem(index)">
                <font-awesome-icon v-b-tooltip.hover.bottom.v-danger icon="trash" class="text-danger" :title="$t('action.delete')" />
              </b-button>
            </b-button-group>
          </b-col>
        </b-row>
      </b-card>

      <footer-form-service v-if="$route.name === 'productShow'" previous next @click:next="$emit('next-tab')" @click:previous="$emit('previous-tab')" />
      <footer-form-service v-else previous save save-next @click:previous="$emit('previous-tab')" @click:save-next="nextRedirection = true" />
    </validation-observer>
  </b-card>
</template>

<script>
import { defineComponent, ref, inject, reactive, computed } from '@vue/composition-api'

import Airport from '@/models/Airport'
import AppBtnAdd from '@/components/AppBtnAdd.vue'
import AirportsCollection from '@/collections/AirportsCollection'
import FooterFormService from '../component/FooterFormService.vue'

export default defineComponent({
  name: 'Airports',

  components: {
    AppBtnAdd,
    FooterFormService,
  },

  setup(_props, ctx) {
    const { toaster, $i18n, $bvModal, alert, $store } = ctx.root
    const { emit: $emit } = ctx

    const product = inject('product')
    product.value.fetchLocations(ctx.root.$route.params.service_id)

    const nextRedirection = ref(false)
    const locationsFormRef = ref(null)

    const airportsProductId = computed(() => product.value.airports.map(({ airport }) => airport.id))
    const selectedOrganization = computed(() => $store.state.auth.selectedOrganization)

    const searchLoader = ref(true)
    const airports = reactive(new AirportsCollection())

    const airportsOptions = ref([])

    airports.fetch({ organizationId: selectedOrganization.value.id, getAllItems: true }).then(() => {
      airportsOptions.value = airports._attributes.items.filter(airport => !airportsProductId.value.includes(airport.id))
      searchLoader.value = false
    })

    const addAirports = () => {
      product.value.airports.push({ active: true, airport: new Airport({ name: $i18n.t('navigation.trip.airport') })._attributes })
    }

    const modalDeleteItem = index => {
      if (!product.value.airports[index].airport.id) product.value.airports.splice(index, 1)
      else {
        $bvModal
          .msgBoxConfirm($i18n.t('alert.delete_confirmation.message'), {
            title: $i18n.t('alert.delete_confirmation.title'),
            size: 'sm',
            okVariant: 'primary',
            okTitle: $i18n.t('common.yes'),
            cancelTitle: $i18n.t('common.no'),
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(isConfirmed => {
            if (isConfirmed) {
              alert($i18n.t('alert.delete.success'))
              product.value.airports.splice(index, 1)
            }
          })
      }
    }

    const onSubmit = async () => {
      let validForm = await locationsFormRef.value.validate()
      product.value.airports.forEach(({ airport }) => {
        if (!airport.id) validForm = false
      })
      if (validForm) {
        await product.value.patchLocations()
        if (nextRedirection.value) {
          $emit('next-tab')
          nextRedirection.value = false
        }
      } else {
        toaster($i18n.t('alert.fill_required_fields'), 'danger', 'AlertTriangleIcon')
      }
    }

    return {
      product,
      locationsFormRef,
      searchLoader,
      airports,
      airportsOptions,
      airportsProductId,
      addAirports,
      modalDeleteItem,
      nextRedirection,
      onSubmit,
    }
  },
})
</script>
