/* eslint-disable class-methods-use-this */

import { omit } from 'lodash'
import { fetchAirportsRequest } from '@/request/globalApi/requests/airportRequests'
import BaseCollection from './_Base'
import Airport from '../models/Airport'

/**
 * AirportsCollection
 * @linkhttps://vuemc.io/#collection-instances
 */
class AirportsCollection extends BaseCollection {
  options() {
    return {
      model: Airport,
    }
  }

  //--------------------------------
  //        API REQUESTS
  //--------------------------------

  /**
   *
   */
  async fetch(params) {
    const { data } = await fetchAirportsRequest(params)
    this.set({
      ...omit(data, 'airports'),
      items: data.airports,
    })
  }
}

export default AirportsCollection
