/* eslint-disable class-methods-use-this */

import _ from 'lodash'
import { Collection } from 'vue-mc'
import globalApi from '@/request/globalApi/globalApi' // our axios instance

/**
 * BaseCollection
 * A base collection class using the application's default axios settings.
 * Note: Always extend from this class, not the vue-mc Model.
 */
class BaseCollection extends Collection {
  defaults() {
    return {
      items: [],
    }
  }

  options() {
    return {
      useDeleteBody: false,
    }
  }

  /**
   * Parses every request made by any Model in the system.
   * @param   {object}  config  Config options for any restful http call.
   * @returns {Request}         A new `Request` using the given configuration and our default axios settings.
   */
  createRequest(config) {
    // globalApi.defaults is our pre-made axios instance, we extend the given config with its default settings.
    config = _.extend(config, globalApi.defaults)
    return super.createRequest(config)
  }
}

export default BaseCollection
